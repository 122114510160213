import React from "react";
import { Card, UncontrolledCollapse, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";

const WardList = (props) => {
  const { t } = useTranslation();

  let avgCount = props.totalActive / props.data.length;

  return (
    <>
      <div className="bg-light d-flex px-3 py-2">
        <span style={{ fontSize: "10px" }}>
          {moment(props.covidSumm_timestamp).format("DD-MM-YYYY HH:mm")}
        </span>
      </div>
      <div class="list-group" style={{ overflowY: "auto" }}>
        {props.data
          .sort(function (a, b) {
            return a.wardNumber - b.wardNumber;
          })
          .map((item, i) => (
            <div
              key={i}
              class="list-group-item p-0 rounded-0 list-group-item-action flex-column align-items-start border-0 border-bottom"
            >
              <div class="d-flex px-3 py-2  w-100 justify-content-between">
                <span class="mb-1 text-medium d-flex flex-column">
                  {t("wardList.wardNo")} {item.wardNumber}
                  {item.isContainment || item.isContainment == "true" ? (
                    <span
                      class="badge bg-danger text-small"
                      style={{ width: "auto" }}
                    >
                      {t("wardList.containment")}
                    </span>
                  ) : item.todaysCount >= avgCount ? (
                    <span
                      class="badge text-small bg-warning"
                      style={{ width: "auto" }}
                    >
                      {t("wardList.highRisk")}
                    </span>
                  ) : (
                    <span
                      class="badge text-small bg-success"
                      style={{ width: "auto" }}
                    >
                      {t("wardList.staySafe")}
                    </span>
                  )}
                </span>
                <div className="center">
                  <span
                    className="text-large center"
                    style={{ marginRight: "10px" }}
                  >
                    {item.todaysCount}
                  </span>
                  <Button
                    id={`toggler${i}`}
                    className="center border-0 transparent"
                    style={{ height: 25, width: 25, color: "black" }}
                  >
                    +
                  </Button>
                </div>
              </div>
              <UncontrolledCollapse
                className="bg-light w-100 mt-1"
                toggler={`#toggler${i}`}
              >
                <div
                  className="w-100 p-3 h-100"
                  dangerouslySetInnerHTML={{
                    __html: item[`info_${localStorage.getItem("locale")}`],
                  }}
                ></div>
              </UncontrolledCollapse>
            </div>
          ))}
      </div>
    </>
  );
};

export default WardList;
