import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import Header from "../../components/Header";
import Guidelines from "./Guidelines";
import WardList from "./WardList";
import { useTranslation } from "react-i18next";
import { fetchCovidGuideline, fetchCovidSumm } from "./redux/covidActions";
import { useDispatch, connect } from "react-redux";

const Covid = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { covidSumm, wardDetails, covidSumm_timestamp } = props;

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    dispatch(fetchCovidSumm("INKL000001"));
    dispatch(fetchCovidGuideline("INKL000001"));
  }, []);

  return (
    <div className="container-fluid">
      <Header title={t("covid.title")} />
      {props.isFetchingGuideline ||
      props.isFetching ||
      wardDetails === null ||
      covidSumm === null ? (
        <div
          className="center"
          style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}
        >
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      ) : (
        <>
          <Card className="shadow1 overflow-hidden" style={{ height: "60px" }}>
            <CardBody className="d-flex center flex-row  p-0 px-2">
              <div
                className="covidSumm ps-1"
                style={{ borderRight: "1px solid #f2f2f2" }}
              >
                <div className="center flex-row">
                  <span
                    className="bg-warning rounded"
                    style={{ height: "6px", width: "9px" }}
                  ></span>
                  <span style={{ fontSize: "10px", marginLeft: "3px" }}>
                    {t("covid.active")}
                  </span>
                </div>
                <span className="text-medium">{covidSumm.active}</span>
              </div>
              <div
                className="covidSumm ps-1"
                style={{ borderRight: "1px solid #f2f2f2" }}
              >
                <div className="center flex-row">
                  <span
                    className="bg-danger rounded"
                    style={{ height: "6px", width: "9px" }}
                  ></span>
                  <span style={{ fontSize: "10px", marginLeft: "3px" }}>
                    {t("covid.deaths")}
                  </span>
                </div>
                <span className="text-medium">{covidSumm.deaths}</span>
              </div>
              <div
                className="covidSumm ps-1"
                style={{ borderRight: "1px solid #f2f2f2" }}
              >
                <div className="center flex-row">
                  <span
                    className="bg-info rounded"
                    style={{ height: "6px", width: "9px" }}
                  ></span>
                  <span style={{ fontSize: "10px", marginLeft: "3px" }}>
                    {t("covid.recovered")}
                  </span>
                </div>
                <span className="text-medium">{covidSumm.recovered}</span>
              </div>
              <div className="covidSumm ps-1">
                <div className="center flex-row">
                  <span
                    className="bg-primary rounded"
                    style={{ height: "6px", width: "9px" }}
                  ></span>
                  <span style={{ fontSize: "10px", marginLeft: "3px" }}>
                    {t("covid.total")}
                  </span>
                </div>
                <span className="text-medium">{covidSumm.total}</span>
              </div>
            </CardBody>
          </Card>
          <Card
            className="shadow1 mt-2 overflow-hidden"
            style={{ height: "calc(100vh - 150px)" }}
          >
            <Nav pills className="nav-fill  rounded ">
              <NavItem style={{ flex: 1, textAlign: "center" }}>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  {t("covid.wardList")}
                </NavLink>
              </NavItem>
              <NavItem style={{ flex: 1, textAlign: "center" }}>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  {t("covid.guidelines")}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="pt-2 overflow-auto" activeTab={activeTab}>
              <TabPane tabId="1">
                <WardList
                  data={wardDetails}
                  totalActive={covidSumm.active}
                  covidSumm_timestamp={covidSumm_timestamp}
                />
              </TabPane>
              <TabPane tabId="2">
                <Guidelines totalActive={covidSumm.active} />
              </TabPane>
            </TabContent>
          </Card>
        </>
      )}
    </div>
  );
};
export default connect((state) => ({
  covidSumm: state.covidReducer.covidSumm,
  wardDetails: state.covidReducer.wardDetails,
  covidSumm_timestamp: state.covidReducer.covidSumm_timestamp,

  isFetching: state.covidReducer.isFetching,
  isFetchingGuideline: state.covidReducer.isFetchingGuideline,
}))(Covid);
