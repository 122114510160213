import React, { useState } from "react";
import { Button } from "reactstrap";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";

const Language = (props) => {
  const [activeLanguage, setActiveLanguage] = useState(
    localStorage.getItem("locale") == null ? "" : localStorage.getItem("locale")
  );
  const { t, i18n } = useTranslation();

  const setLanguage = (locale) => {
    localStorage.setItem("locale", locale);
    if (props.setLocale) {
      props.setLocale(locale);
    } else {
      setActiveLanguage(locale);
      i18n.changeLanguage(locale);
    }
  };

  return (
    <div className="container-fluid">
      <Header title={t("language.title")} noBack={props.noBack} />
      <div class="center flex-column" style={{ height: "calc(100vh - 100px)" }}>
        <Button
          onClick={() => setLanguage("english")}
          className="rounded-pill"
          color="info"
          size="lg"
          outline={activeLanguage == "malayalam" || activeLanguage == ""}
        >
          {t("language.english")}
        </Button>
        <Button
          onClick={() => setLanguage("malayalam")}
          className="rounded-pill mt-3"
          color="info"
          size="lg"
          outline={activeLanguage == "english" || activeLanguage == ""}
        >
          {t("language.malayalam")}
        </Button>
      </div>
      <div
        style={{
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          bottom: 0,
          position: "absolute",
          textAlign: "center",
        }}
      >
        1.0.0.3.1
      </div>
    </div>
  );
};
export default Language;
