import axios from "axios";
import { FETCH_CONTACTS_API } from "../../../variables/API_ENDPOINTS";

export function fetchContacts(serviceArea_code) {
  return function (dispatch) {
    dispatch({
      type: "FETCH_CONTACTS",
    });
    axios
      .post(FETCH_CONTACTS_API, { admin: false, serviceArea_code })
      .then((res) => {
        dispatch({
          type: "FETCH_CONTACTS_SUCCESS",
          payload: JSON.parse(res.data.body),
        });
      });
  };
}
