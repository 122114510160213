import React from "react";
import { useTranslation } from "react-i18next";
import { UncontrolledCollapse, Button } from "reactstrap";
import { COLORS } from "../../variables/colors";
import { connect } from "react-redux";
import moment from "moment";

const Guidelines = (props) => {
  const { t } = useTranslation();
  const { covidGuidelines } = props;

  return (
    <>
      <div className="bg-light d-flex px-3 py-2">
        <span style={{ fontSize: "10px" }}>
          {moment(props.covidGuide_timestamp).format("DD-MM-YYYY HH:mm")}
        </span>
      </div>
      <div class="list-group">
        {covidGuidelines !== null &&
          covidGuidelines.map((item, i) => (
            <div
              key={i}
              class="d-flex p-0 flex-column list-group-item rounded-0 list-group-item-action  align-items-start border-0 border-bottom"
            >
              <div className="d-flex w-100 p-2 align-items-center">
                <div
                  className="rounded-circle center"
                  style={{
                    height: 50,
                    width: 50,
                    flexShrink: 1,
                    backgroundColor: COLORS[i],
                  }}
                >
                  <span class="material-icons text-medium">{item.icon}</span>
                </div>
                <div
                  class="ps-2 d-flex align-items-center justify-content-between"
                  style={{ flex: 1 }}
                >
                  <div>
                    <span class="text-medium d-block">
                      {item[`name_${localStorage.getItem("locale")}`]}
                    </span>
                    <div>
                      {item.isOpen ? (
                        <span
                          class="badge bg-success text-small"
                          style={{ width: "50px" }}
                        >
                          {t("guidelines.open")}
                        </span>
                      ) : (
                        <span
                          class="badge bg-danger text-small"
                          style={{ width: "50px" }}
                        >
                          {t("guidelines.closed")}
                        </span>
                      )}
                      {item.timings ? (
                        <span
                          class="badge bg-primary text-small ms-2"
                          style={{ width: "90px" }}
                        >
                          {item.timings}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <Button
                    id={`toggler${i}`}
                    className="center border-0 transparent"
                    style={{ height: 25, width: 25, color: "black" }}
                  >
                    +
                  </Button>
                </div>
              </div>
              <UncontrolledCollapse
                className="bg-light w-100 mt-1"
                toggler={`#toggler${i}`}
              >
                <div
                  className="w-100 p-3  h-100"
                  dangerouslySetInnerHTML={{
                    __html: item[`info_${localStorage.getItem("locale")}`],
                  }}
                ></div>
              </UncontrolledCollapse>
            </div>
          ))}
      </div>
    </>
  );
};

export default connect((state) => ({
  covidGuidelines: state.covidReducer.covidGuidelines,
  covidGuide_timestamp: state.covidReducer.covidGuide_timestamp,
}))(Guidelines);
