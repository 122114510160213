import axios from "axios";
import { FETCH_BROADCASTDATA_API } from "../../../variables/API_ENDPOINTS";

export function fetchBroadcastData(serviceArea_code) {
  return function (dispatch) {
    dispatch({
      type: "FETCH_BROADCAST_DATA",
    });
    axios
      .post(FETCH_BROADCASTDATA_API, JSON.stringify({ serviceArea_code }))
      .then((res) => {
        dispatch({
          type: "FETCH_BROADCAST_DATA_SUCCESS",
          payload: JSON.parse(res.data.body),
        });
      });
  };
}
