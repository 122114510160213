export default function broadcastReducer(
  state = {
    broadcastData: [],
    isFetching: false,
    errorMessage: "",
  },
  action
) {
  switch (action.type) {
    case "FETCH_BROADCAST_DATA":
      return {
        ...state,
        isFetching: true,
      };
    case "FETCH_BROADCAST_DATA_SUCCESS": {
      return {
        ...state,
        broadcastData: action.payload,
        isFetching: false,
      };
    }

    default:
      return state;
  }
}
