import { combineReducers } from "redux";

import broadcastReducer from "../../pages/broadcast/redux/broadcastReducer";
import contactsReducer from "../../pages/contacts/redux/contactsReducer";
import covidReducer from "../../pages/covid/redux/covidReducer";

export default combineReducers({
  broadcastReducer,
  contactsReducer,
  covidReducer,
});
