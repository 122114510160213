import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";
import { COLORS } from "../../variables/colors";
import { fetchContacts } from "./redux/contactsAction";
import { connect, useDispatch } from "react-redux";
import Header from "../../components/Header";

const Contacts = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchContacts("INKL000001"));
  }, []);
  const { data, isFetching } = props;
  return (
    <div class="container-fluid h-100" style={{ overflow: "hidden" }}>
      <Header title={t("contacts.title")} />
      {isFetching || data === null ? (
        <div
          className="center"
          style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}
        >
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      ) : (
        <div style={{ overflow: "auto", height: "calc(100% - 75px)" }}>
          <h3 className="ps-2 pt-3" style={{ height: "30px" }}>
            {t(`contacts.emergency`)}
          </h3>
          <Card className="shadow1">
            <CardBody className="p-0 h-100 py-2">
              <div class="list-group h-100">
                {data["emergency"].map((item, i) => (
                  <div
                    key={i}
                    class="d-flex flex-row list-group-item rounded-0 list-group-item-action  align-items-start border-0 "
                    style={{ padding: "0.2rem 1rem", height: "90px" }}
                  >
                    <div
                      className="rounded-circle center"
                      style={{
                        height: 50,
                        width: 50,
                        flexShrink: 1,
                        background: COLORS[i],
                        alignSelf: "center",
                      }}
                    >
                      {item.contact_icon === "logo" ? (
                        <img
                          src={`./assets/img/logos/${item.serviceArea_code}/${item.contact_icon_value}.png`}
                          height="100%"
                          width="100%"
                        />
                      ) : item.contact_icon === "font" ? (
                        <span className="material-icons text-medium">
                          {item.contact_icon_value}
                        </span>
                      ) : (
                        <span className="text-medium">
                          {item.contact_icon_value}
                        </span>
                      )}
                    </div>
                    <div
                      class="center h-100 justify-content-between px-2 border-0 border-bottom"
                      style={{ flex: 1 }}
                    >
                      <div className="d-flex flex-column align-items-start justify-content-center p-2">
                        <span class="text-16 pb-1 ellipse">
                          {
                            item[
                              `contact_name_${localStorage.getItem("locale")}`
                            ]
                          }
                        </span>

                        <span
                          class="text-small d-flex pb-1"
                          style={{ color: "grey", lineHeight: "10px" }}
                        >
                          {item.contact_number}
                        </span>

                        {item.contact_description !== "" ? (
                          <span
                            class="text-small d-flex pb-1"
                            style={{ color: "grey" }}
                          >
                            {item.contact_description}
                          </span>
                        ) : null}
                      </div>

                      <a
                        class="material-icons"
                        href={`tel:${item.contact_number}`}
                      >
                        call
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
          {Object.keys(data)
            .filter((item) => item !== "emergency")
            .sort()
            .map((item, i) => (
              <>
                <h3 className="ps-2 pt-3" style={{ height: "30px" }}>
                  {t(`contacts.${item}`)}
                </h3>
                <Card className="shadow1">
                  <CardBody className="p-0 h-100 py-2">
                    <div class="list-group h-100">
                      {data[item].map((item) => (
                        <div
                          key={i}
                          class="d-flex flex-row list-group-item rounded-0 list-group-item-action  align-items-start border-0 "
                          style={{ padding: "0.2rem 1rem", height: "90px" }}
                        >
                          <div
                            className="rounded-circle center"
                            style={{
                              height: 50,
                              width: 50,
                              flexShrink: 1,
                              background: COLORS[i],
                              alignSelf: "center",
                            }}
                          >
                            {item.contact_icon === "logo" ? (
                              <img
                                src={`./assets/img/logos/${item.serviceArea_code}/${item.contact_icon_value}.png`}
                                height="100%"
                                width="100%"
                              />
                            ) : item.contact_icon === "font" ? (
                              <span className="material-icons text-medium">
                                {item.contact_icon_value}
                              </span>
                            ) : (
                              <span className="text-medium">
                                {item.contact_icon_value}
                              </span>
                            )}
                          </div>
                          <div
                            class="center h-100 justify-content-between px-2 border-0 border-bottom"
                            style={{ flex: 1 }}
                          >
                            <div className="d-flex flex-column align-items-start justify-content-center p-2">
                              <span class="text-16 pb-1 ellipse">
                                {
                                  item[
                                    `contact_name_${localStorage.getItem(
                                      "locale"
                                    )}`
                                  ]
                                }
                              </span>

                              <span
                                class="text-small d-flex pb-1"
                                style={{ color: "grey", lineHeight: "10px" }}
                              >
                                {item.contact_number}
                              </span>

                              {item.contact_description !== "" ? (
                                <span
                                  class="text-small d-flex pb-1"
                                  style={{ color: "grey" }}
                                >
                                  {item.contact_description}
                                </span>
                              ) : null}
                            </div>

                            <a
                              class="material-icons"
                              href={`tel:${item.contact_number}`}
                            >
                              call
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </>
            ))}
        </div>
      )}
    </div>
  );
};

export default connect((state) => ({
  data: state.contactsReducer.contacts,
  isFetching: state.contactsReducer.isFetching,
}))(Contacts);
