import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import { SUBSCRIBE_NOTIFICATION } from "./variables/API_ENDPOINTS";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBL2IBFSD3GJo9m32kvqMKvCiGo2d8IWrI",
  authDomain: "onehub-qis.firebaseapp.com",
  // databaseURL: "https://onehub-qis.firebaseio.com",
  projectId: "onehub-qis",
  storageBucket: "onehub-qis.appspot.com",
  messagingSenderId: "946794656540",
  appId: "1:946794656540:web:e9fd9692bb4495d5fd40d9",
  measurementId: "G-BSD73NH4R9",
};

// Initialize Firebase
class Initializer extends React.PureComponent {
  componentDidMount() {
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    let oldSubscriptionKey = localStorage.getItem("subscriptionKey");

    getToken(messaging, {
      vapidKey:
        "BPIh47jVqhmsxYIopQVHmz8C-3Zf4Gmpd7o5wCjZIutavuUQEywyvRJAJzFzWA-sYypoD0LfKP3M9Sfb852aFEU",
    })
      .then((currentToken) => {
        if (currentToken) {
          if (oldSubscriptionKey !== null) {
            if (oldSubscriptionKey !== currentToken) {
              this.subscribeToken(currentToken);
              localStorage.setItem("subscriptionKey", currentToken);
            }
          } else {
            this.subscribeToken(currentToken);
            localStorage.setItem("subscriptionKey", currentToken);
          }
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });

    axios.defaults.baseURL = "https://onehub.qis.digital/app/api/";
  }
  subscribeToken = (token) => {
    axios
      .post(SUBSCRIBE_NOTIFICATION, { token })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };
  render() {
    return this.props.children;
  }
}

export default connect()(Initializer);
