import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

// import Swiper core and required modules
import SwiperCore, { Autoplay } from "swiper/core";
import { Card } from "reactstrap";
// install Swiper modules
SwiperCore.use([Autoplay]);

export const List = () => {
  const locale = localStorage.getItem("locale");

  return (
    <>
      <Swiper
        autoSave={true}
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={30}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <Card>
            <img src={`./assets/img/slider/INKL000001/${locale}/01.jpg`} />
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card>
            <img src={`./assets/img/slider/INKL000001/${locale}/02.jpg`} />
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card>
            <img src={`./assets/img/slider/INKL000001/${locale}/03.jpg`} />
          </Card>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
