import React from "react";
import { Link } from "react-router-dom";

const Header = (props) => {
  return (
    <div
      className="w-100 center justify-content-start"
      style={{ height: "70px" }}
    >
      {props.noBack ? (
        <div
          className="d-flex header-link align-items-center flex-row"
          style={{ paddingTop: "20px" }}
        >
          <h2 className="ps-2 fw-lighter">{props.title}</h2>
        </div>
      ) : (
        <Link
          to={props.backTo ? props.backTo : "/home"}
          className="d-flex header-link align-items-center flex-row"
          style={{ paddingTop: "20px" }}
        >
          <span class="material-icons">arrow_back_ios_new</span>
          <h2 className="ps-2 fw-lighter">{props.title}</h2>
        </Link>
      )}
    </div>
  );
};
export default Header;
