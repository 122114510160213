export default function broadcastReducer(
  state = {
    covidSumm: null,
    wardDetails: null,
    covidSumm_timestamp: null,
    covidGuidelines: null,
    covidGuide_timestamp: null,
    isFetchingGuideline: false,
    isFetching: false,
    errorMessage: "",
  },
  action
) {
  switch (action.type) {
    case "FETCH_COVIDSUMM":
      return {
        ...state,
        isFetching: true,
      };
    case "FETCH_COVIDSUMM_SUCCESS": {
      return {
        ...state,
        covidSumm: action.payload.covidSumm,
        wardDetails: action.payload.wardDetails,
        covidSumm_timestamp: action.payload.covidSumm_timestamp,
        isFetching: false,
      };
    }

    case "FETCH_COVIDGUIDELINE":
      return {
        ...state,
        isFetchingGuideline: true,
      };
    case "FETCH_COVIDGUIDELINE_SUCCESS": {
      return {
        ...state,
        covidGuidelines: action.payload.guidelines,
        covidGuide_timestamp: action.payload.covidGuide_timestamp,
        isFetchingGuideline: false,
      };
    }

    default:
      return state;
  }
}
