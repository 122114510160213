import moment from "moment";

export default function contactsReducer(
  state = {
    contacts: null,
    isFetching: false,
    errorMessage: "",
  },
  action
) {
  switch (action.type) {
    case "FETCH_CONTACTS":
      return {
        ...state,
        isFetching: true,
      };
    case "FETCH_CONTACTS_SUCCESS": {
      return {
        ...state,
        contacts: action.payload,
        isFetching: false,
      };
    }

    default:
      return state;
  }
}
