import React, { useEffect } from "react";
import {
  Card,
  CardText,
  CardBody,
  CardLink,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import { connect, useDispatch } from "react-redux";
import { fetchBroadcastData } from "./redux/broadcastActions";
import moment from "moment";
const Broadcast = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shareLink = (title, msg) => {
    if (navigator.share) {
      navigator
        .share({
          title: `oneHub Broadcast | ${title}`,
          text: msg.replace(/(<([^>]+)>)/gi, ""),
          url: "https://play.google.com/store/apps/details?id=qis.onehub.app.lite",
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    }
  };
  useEffect(() => {
    dispatch(fetchBroadcastData("INKL000001"));
  }, []);
  return (
    <div className="container-fluid">
      <Header title={t("broadcast.title")} backTo="/home" />

      {props.isFetching || props.broadcastData.length === 0 ? (
        <div
          className="center"
          style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}
        >
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      ) : (
        <div style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}>
          {props.broadcastData
            .sort(function (a, b) {
              return b.msg_timestamp - a.msg_timestamp;
            })
            .map((msg, i) => (
              <Card key={i} className="my-2 shadow1">
                <CardBody>
                  <div className="d-flex align-items-center justify-content-between flex-row">
                    <div className="center flex-row">
                      <img
                        src={`./assets/img/logos/${
                          msg.serviceArea_code
                        }/${msg.messenger_category
                          .replace(/\s/g, "")
                          .toLowerCase()}.png`}
                        style={{ height: "45px", width: "45px" }}
                        class="rounded-circle"
                      />

                      <div
                        className=" d-flex align-items-start flex-column justify-content-center ps-2"
                        style={{ height: "45px" }}
                      >
                        <CardTitle tag="h3">
                          {msg[`messenger_${localStorage.getItem("locale")}`]}
                        </CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">
                          {moment(msg.msg_timestamp).format("DD-MM-YYYY HH:mm")}
                        </CardSubtitle>
                      </div>
                    </div>
                    <Button
                      onClick={() =>
                        shareLink(
                          msg[`messenger_${localStorage.getItem("locale")}`],
                          msg[`msg_${localStorage.getItem("locale")}`].substr(
                            0,
                            100
                          )
                        )
                      }
                      className="transparent ml-auto border-0"
                    >
                      <i className="material-icons text-medium text-info">
                        share
                      </i>
                    </Button>
                  </div>
                </CardBody>
                <CardBody className="px-3 py-0">
                  <CardText
                    dangerouslySetInnerHTML={{
                      __html: msg[`msg_${localStorage.getItem("locale")}`],
                    }}
                  ></CardText>
                  <CardLink href="#"></CardLink>
                  <CardLink href="#"></CardLink>
                </CardBody>
              </Card>
            ))}
        </div>
      )}
    </div>
  );
};

export default connect((state) => ({
  broadcastData: state.broadcastReducer.broadcastData,
  isFetching: state.broadcastReducer.isFetching,
}))(Broadcast);
