import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Card, Row, Col } from "reactstrap";
import { List } from "../components/ImageSlider";

const Home = (props) => {
  const { t } = useTranslation();

  function generateGreetings() {
    var currentHour = moment().format("HH");

    if (currentHour >= 3 && currentHour < 12) {
      return t("home.morning");
    } else if (currentHour >= 12 && currentHour < 15) {
      return t("home.afternoon");
    } else if (currentHour >= 15) {
      return t("home.evening");
    } else {
      return t("home.hello");
    }
  }

  return (
    <div className="container-fluid p-0">
      <div className="widgets-container justify-content-between align-items-center flex-column">
        <div className="w-100 center  flex-column" style={{ height: "70px" }}>
          <div className="center px-4  py-1 w-100 justify-content-between">
            <img src="./assets/img/logos/onehub.png" width="50" />
            <Link className="miniapp-icon " to="/langSelection">
              <span class="material-icons">settings</span>
            </Link>
          </div>
        </div>
        <div className="p-4 align-self-start">
          <h3 style={{ lineHeight: "5px" }}>
            {generateGreetings()}{" "}
            <img src="./assets/img/hand.png" width="15px" />
          </h3>
          <h2>{t("home.user")}</h2>
        </div>
        <List />
        <Card className="p-3 shadow1" style={{ width: "90vw", height: "auto" }}>
          <Row>
            <Col>
              <Link className="miniapp-icon" to="/broadcast">
                <div
                  className="rounded-circle center"
                  style={{ height: 60, width: 60, backgroundColor: "#E3F1FC" }}
                >
                  <span class="material-icons">campaign</span>
                </div>
                {t("home.broadcast")}
              </Link>
            </Col>
            <Col>
              <Link className="miniapp-icon" to="/covid">
                <div
                  className="rounded-circle center"
                  style={{ height: 60, width: 60, backgroundColor: "#EEE0E5" }}
                >
                  <span class="material-icons text-danger">coronavirus</span>
                </div>
                {t("home.covid")}
              </Link>
            </Col>
            <Col>
              <Link className="miniapp-icon" to="/contacts">
                <div
                  className="rounded-circle center"
                  style={{
                    height: 60,
                    width: 60,
                    backgroundColor: "rgb(232 233 234)",
                  }}
                >
                  <span class="material-icons text-primary">contact_phone</span>
                </div>
                {t("home.contacts")}
              </Link>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default Home;
