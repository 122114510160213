import React, { useState, Suspense } from "react";

import "./App.css";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./pages/Home";
import Covid from "./pages/covid/Covid";
import Language from "./pages/Language";

import Broadcast from "./pages/broadcast/Broadcast";
import Contacts from "./pages/contacts/Contacts";

function App() {
  const [locale, setLocale] = useState(
    localStorage.getItem("locale") == null ? "" : localStorage.getItem("locale")
  );

  if (locale === "") {
    return (
      <Suspense
        fallback={
          <div
            className="center"
            style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}
          >
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        }
      >
        {" "}
        <Language setLocale={setLocale} noBack={true} />
      </Suspense>
    );
  }
  return (
    <Suspense fallback="loading">
      <Router>
        {/* <Navbar color="light" light expand="md">
     <NavbarToggler onClick={toggle} />
      </Navbar>  */}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route exact path="/langSelection">
            <Language noBack={false} />
          </Route>
          <Route exact path="/broadcast">
            <Broadcast />
          </Route>
          <Route exact path="/covid">
            <Covid />
          </Route>
          <Route exact path="/contacts">
            <Contacts />
          </Route>
          <Redirect exact from="/" to="/home" />
        </Switch>
        {/* <nav class="menu" style={{position:"fixed"}}>
		<a href="#" class="menu-item">
			<span class="material-icons">sports_esports</span>
			<span class="menu-item-label">Home</span>
		</a>
		<a href="#" class="menu-item">
			<span class="material-icons">camera_alt</span>
			<span class="menu-item-label">Messages</span>
		</a>
		<a href="#" class="menu-item">
			<span class="material-icons">movie</span>
			<span class="menu-item-label">SOS</span>
		</a>
	
	</nav> */}
      </Router>
    </Suspense>
  );
}

export default App;
